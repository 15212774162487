* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.mainContainer {

}



.dashboardContainer {

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 40px 50px;

        .logoutBtn {
            cursor: pointer;
          }
          .logoutBtn:hover {
            color: black;
          }
      }

      .contentContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* background-color: blue; */

        .tokenListContainer {
            width: 900px;
            display: flex;
            flex-direction: column;
            /* background-color: red; */

            .itemsContainer {
                background-color: white;
                margin-bottom: 30px;
                padding: 30px 40px;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
                display: flex;
                flex-direction: column;
                /* align-items: center; */
                /* justify-content: space-between; */

                .info {
                    font-size: 12px;
                  }

                  .listItem {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    /* background-color: green; */
                    justify-content: space-between;
                    align-items: center;
                    /* background-color: white;
                    margin-bottom: 30px;
                    padding: 30px 40px;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */

                    .buttonsArea {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 14px;
                        /* background-color: orange; */
    
                        .copyBtn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 10px 15px;
                            border: 1px solid black;
                            border-radius: 5px;
                            margin-right: 20px;
                            cursor: pointer;
                          }
                          
                          .copyBtn:hover {
                            background-color: black;
                            color: white;
                          }

                          .remove {
                            cursor: pointer;
                          }
                          
                          .remove:hover {
                            color: red;
                          }
                      }
                  }

               
              }
          }
      }
    
}

.button37 {
    background-color: #13aa52;
    border: 1px solid #13aa52;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: 'Akzidenz Grotesk BQ Medium', -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    outline: 0;
    padding: 10px 25px;
    text-align: center;
    transform: translateY(0);
    transition: transform 150ms, box-shadow 150ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin-bottom: 50px;
  }
  
  .button37:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
    transform: translateY(-2px);
  }
  
  @media (min-width: 768px) {
    .button37 {
      padding: 10px 30px;
    }
  }

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 100px;

      
      .logo {
          padding-bottom: 38px;
      }

      .mainBox {
          max-width: 400px;
          padding: 50px 70;
          border-radius: 8px;
          background: #FFF;
          box-shadow: 0px 0px 102.5px 0px rgba(0, 0, 0, 0.10);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;  
          font-family: Poppins; 

          a {
              color: #007DFA;
              text-decoration: none;
          }

          a:hover {
              color: #0064c8;
              text-decoration: underline;
          }

          
          .title {
              font-size: 28px;
              font-style: normal;
              font-weight: 600;
              color: #101012;
              // background-color: #007DFA;
          }

          form {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding-top: 25px;
              // background-color: aquamarine;
              width: 100%;

              
              .inputArea {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: start;
                  justify-content: center;
                  // background-color: aqua;
                  margin-bottom: 24px;
                  
                  label {
                      font-size: 16px;
                      font-weight: 400;
                      color: #5A5A5D;
                      padding-bottom: 2px;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                      width: 100%;
                  }

                  input {
                      width: 100%;
                      // background-color: blueviolet;
                      box-sizing: border-box;
                      padding: 12px 16px;
                      border-radius: 8px;
                      border: 1px solid #BEBEBF;
                      color: #5A5A5D;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                  }
              }

              .submitBtn {
                  width: 100%;
                  margin-top: 25px;
                  height: 52px;
                  padding: 16px;
                  background-color: #007DFA;
                  color: #fff;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  border-radius: 8px;
                  border: 0;
              }

              .submitBtn:hover {
                  background-color: #0064c8;
                  cursor: pointer;
              }

              .boxFooter {
                  margin-top: 20px;
                  color: #BEBEBF;


              }
          }
      }
  }



}

h2 {
    padding: 0;
    margin: 0;
}

.noTokens {
  display: flex;
  flex-direction: column;
  // background-color: #007DFA;
  align-items: center;
  justify-content: center;
  padding: 50px;
}