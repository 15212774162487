import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import logo from './assets/logo.png';
import * as style from './App.module.scss';

const isEnvDev = (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && true) || false;
// const BASE_API_URL = isEnvDev ? 'http://127.0.0.1:5000' : 'https://api.londax.ai';
const BASE_API_URL = 'https://api.londax.ai';
// const BASE_API_URL = 'http://54.235.175.92:5001';

const Item = ({ data, handleReload }) => {
  const textAreaRef = useRef(null);

  const copyToClipboard = () => {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = 'https://app.londax.ai/register?invite=' + data[1];
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text
    document.execCommand('copy');

    // Remove the textarea element
    document.body.removeChild(textarea);

    // Optionally, you can provide feedback to the user
    toast.success('Copied to clipboard');
  };

  const removeToken = async (body) => {
    const route = '/remove-token';
    const res = await fetch(BASE_API_URL + route, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const data = {
      ok: res.ok,
      status: res.status,
      data: await res.json(),
    };

    // console.log('++++ data ', data);

    return data;
  };

  const handleRemoveToken = (tokenId) => {
    removeToken({ token_id: tokenId }).then((res) => {
      if (res.ok) {
        toast('Token removed', {
          icon: '🔥',
        });
        handleReload();
      }
    });
  };
  return (
    <div className={style.itemsContainer}>
      <div className={style.info}>
        Created: {data[3]}, {data[2] ? <p style={{ color: 'red' }}>Not Active</p> : <p style={{ color: 'green' }}>Active</p>}
      </div>
      <div className={style.listItem}>
        https://app.londax.ai/register?invite={data[1]}
        <div className={style.buttonsArea}>
          <div className={style.copyBtn} onClick={copyToClipboard}>
            Copy
          </div>
          <div className={style.remove} onClick={() => handleRemoveToken(data[0])}>
            Remove
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = ({ handleLogOut }) => {
  const [tokens, setTokens] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getTokens().then((res) => {
      if (res.ok) {
        console.log(res);
        setTokens(res.data.tokens);
      }
    });
  }, [reload]);

  const getTokens = async () => {
    const route = '/get-tokens';
    const res = await fetch(BASE_API_URL + route, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(body),
    });

    const data = {
      ok: res.ok,
      status: res.status,
      data: await res.json(),
    };

    // console.log('++++ data ', data);

    return data;
  };

  const generateToken = async () => {
    const route = '/generate-token';
    const res = await fetch(BASE_API_URL + route, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify(body),
    });

    const data = {
      ok: res.ok,
      status: res.status,
      data: await res.json(),
    };

    // console.log('++++ data ', data);

    return data;
  };

  const handleGenerateToken = () => {
    generateToken().then((res) => {
      if (res.ok) {
        toast.success('Successfully created new token!');
        setReload(!reload);
      }
    });
  };

  const handleReload = () => {
    setReload(!reload);
  };

  return (
    <div className={style.dashboardContainer}>
      <div className={style.header}>
        <img src={logo} />
        <div className={style.logoutBtn} onClick={() => handleLogOut()}>
          Logout
        </div>
      </div>
      <div className={style.contentContainer}>
        <div className={style.button37} onClick={handleGenerateToken}>
          Create New Token
        </div>
        <div className={style.tokenListContainer}>
          {tokens.length != 0 ? (
            tokens.map((item) => <Item key={item[0]} handleReload={handleReload} data={item} />)
          ) : (
            <div className={style.noTokens}>No tokens found</div>
          )}
        </div>
      </div>
      <Toaster
        position='top-center'
        reverseOrder={false}
        gutter={8}
        containerClassName=''
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </div>
  );
};

const Login = ({ handleLogin }) => {
  const [password, setPassword] = useState('');
  const [formData, setFormData] = useState({ password: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (formData) => {
    handleLogin(formData['password']);
  };

  return (
    // <div className={style.loginContainer}>
    //   Login
    //   <div className={style.loginBtn} onClick={() => handleLogin(password)}>
    //     Login
    //   </div>
    // </div>
    <div className={style.loginContainer}>
      <div className={style.container}>
        <img className={style.logo} src={logo} alt='logo' />
        <div className={style.mainBox}>
          <h2 className={style.title}>Login to admin account</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(formData);
            }}
          >
            <div className={style.inputArea}>
              <label htmlFor='password'>
                Password:
                <div></div>
              </label>
              <input type='password' name='password' value={formData.password} onChange={handleChange} />
            </div>
            <input className={style.submitBtn} type='submit' value='Login Now' />
          </form>
        </div>
      </div>
      <Toaster
        position='top-center'
        reverseOrder={false}
        gutter={8}
        containerClassName=''
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: '',
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </div>
  );
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    console.log(authCheck());
    authCheck() ? setIsAuthenticated(true) : setIsAuthenticated(false);
  }, []);

  const handleLogin = (password) => {
    console.log(password);
    if (password === 'adminadmin') {
      localStorage.setItem('isAdminAuthenticated', 'true');
      setIsAuthenticated(true);
    } else {
      toast.error('Wrong password');
    }
  };

  const handleLogOut = () => {
    console.log('logout');
    localStorage.removeItem('isAdminAuthenticated');

    setIsAuthenticated(false);
  };

  const authCheck = () => {
    return localStorage.getItem('isAdminAuthenticated') === 'true';
  };

  return isAuthenticated ? <Dashboard handleLogOut={handleLogOut} /> : <Login handleLogin={handleLogin} />;
};

export default App;
